<template>
  <div class="page">
      <div class="titre">
        <h1> Bienvenue sur le site de : </h1>
      </div>
      <br><br>
      <div class="logo">
        <img alt="Vue logo" src="./assets/logo.png" style="height: 20%; width: 20%;">
        <img alt="Vue logo" src="./assets/logo2.png" style="height: 35%; width: 35%; padding-left: 4%;">
      </div>
      <br><br>
      <div class="body">
        <h1> Site en cours de création, merci de patienter ! </h1>
      </div>
    </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.page{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* border: 1px solid red;   */
}

.titre{
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.body{
  display: flex;
  justify-content: center;
  align-items: center;
  
}

</style>
